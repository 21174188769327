$fa-font-path: "../../fonts";
$icon-font-path: "../../fonts/";
$screaming_hand_blue: #15B5F3;
$screaming_hand_red: #E70013;
$screaming_hand_pink: #E20079;

@import '_bootstrap';
@import 'font-awesome';
@import url(http://fonts.googleapis.com/css?family=Titillium+Web:400,700italic,900,700);
@import url(http://fonts.googleapis.com/css?family=Raleway);

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

h1,h2,h3,h4 {
	font-family: 'Titillium Web', sans-serif;
	font-weight: 700;
	font-style: italic;
}

img {
	max-width: 100%;
	height: auto;
}

#poster-pre-launch {
	margin-top: 60px;
}

.section {
	display: none;
	font-size: 18px;
	font-family: 'Raleway', sans-serif;
}

.audio-player {
	
	background-color: $screaming_hand_blue;
	@include border-radius(20px);
	padding: 10px 0px;
	margin-bottom: 30px;
	
	.audio-player-controls {
		
		margin-bottom: 10px;
		
		.fa {
			cursor: pointer;
			color: #fff;
		}
	}
}

#splash {
	
	margin-bottom: 60px;
	
	h1, h2 {
		
		margin: 6px 0px;
		
	}
	
}

#audio-tour {
	
	#audio-tour-instructions {
		font-size: 18px;
		font-family: 'Raleway', sans-serif;
		padding: 10px 0 20px 0;
		
		.fa {
			color: $screaming_hand_blue;
		}
	}
	
	#artists {
		
		h2 {
			margin-top: 12px;
		}
		
		h3 {
			color: $screaming_hand_red;
		}
		
		.panel {
			border: none;
		}
		
		.panel-heading {
			cursor: pointer;
			background-color: $screaming_hand_blue;
			text-transform: uppercase;
			border: none;
			border-radius: 0;
			color: #fff;
			
			.fa {
				margin-top: 8px;
			}
			
			.fa-chevron-circle-up {
				display: none;
			}
			
		}
		
		.panel-body {
			font-size: 16px;
			font-family: 'Raleway', sans-serif;
		}
		
		a:link, a:visited {
			color: $screaming_hand_blue;
		}
		
		a:hover, a:active {
			color: $screaming_hand_red;
			text-decoration: none;
		}
		
	}
}

.section-nav {
	
	.thumbnail {
		
		cursor: pointer;
		
		h2 {
			
			color: $screaming_hand_blue;
			margin: 4px 0px;
			
		}
		
	}
	
}

#history-of-the-hand {
	
	.captions {
		
		font-family: 'Titillium Web', sans-serif;
		text-align: center;
		font-size: 14px;
		margin: 10px 0px 30px 0px;
		font-weight: 700;
		
	}
	
}

#tour-dates {
	
	font-size: 16px;
	font-family: 'Raleway', sans-serif;
	
	.tour-date {
		
		margin: 6px 0px 12px 0px;
		padding: 6px 0px;
		font-family: 'Titillium Web', sans-serif;
		font-size: 18px;
		
		.date {
			
			font-style: italic;
			
		}
		
		.location {
			
			font-weight: 700;
			
		}
		
		.address {
			
			font-size: 14px;
			
		}
		
		.tba {
			font-size: .7em;
			color: #ECECEC;
		}
		
	}
	
	a {
		color: $screaming_hand_pink;
	}
	
}

#footer {
	
	margin-top: 60px;
	font-family: 'Titillium Web', sans-serif;
	
	a:link, a:visited, a:hover, a:active {
		color: $screaming_hand_blue;
	}
	
	#footer-nav {
		display: none;
	}
	
	.sponsors {
		
		img {
			margin: 0px 20px;
		}	
		
	}
	
	.social {
		
		margin: 20px 0px;
		color: $screaming_hand_blue;
		
		img {
			margin: 6px 0px;
		}
		
	}
	
	.hashtags {
		
		color: $screaming_hand_blue;
		
		font-style: italic;
		
	}
	
	.website {
		
		margin: 20px 0px;
		font-size: 18px;		
	}
	
}

// @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
	
// 	#sections {
		
// 		.thumbnail {
			
// 			h2 {
				
// 				font-size: 22px;
				
// 			}
			
// 		}
		
// 	}
	
// }

.btn-language {
	
	color: #FFF;
	background-color: $screaming_hand_blue;
	border-color: #FFF;
	
}

.btn-language:hover, .btn-language:active {
	
	border-color: #FFF;
	color: #FFF;
	background-color: $screaming_hand_pink;
	
}

body {
	padding-top:50px;
}

.navbar-inverse {
	background-color: $screaming_hand_blue;
	border-bottom-color: #FFF;
	
	.navbar-nav > li > a {
		
		color: #FFF;
		
	}
	
	.navbar-toggle {
		
		border-color: #FFF;
		padding: 3px 5px;
		
		.fa {
			
			color: #FFF;
				
		}
		
	}
	
	.navbar-toggle:hover,
	.navbar-toggle:focus {
		
		background-color: #FFF;
		
		.icon-bar {
			background-color: $screaming_hand_blue;
		}
		
		.fa {
			
			color: $screaming_hand_blue;
				
		}
		
	}
	
	.navbar-collapse,
	.navbar-form {
		
		border-color: #FFF;
		
	}
	
	.navbar-right {
		margin-right: 0px;
	}
	
}